import React from 'react'
import Icon from './icons'
import styles from './messageScroller.module.css'
import { cn } from '../lib/helpers'


function MessageScroller (props) {
  const repeatScrollerMessage = 32 

  return (
    <a  href="https://www.livehire.com/widgets/job-listings/tbwa/public?multiSegment=true"  className={styles.container}>
      <span className={cn(styles.message, styles.hide)} >
          We're
          <Icon symbol='arrowTopLeft' />
      </span>

      <div className={styles.scroll}>
        {
          [...Array(repeatScrollerMessage)].map((e, i) => 
            <span className={styles.message} key={i}>
              {props.message}
              <Icon symbol='arrowTopLeft' />
            </span>
          )
        }
      </div>
    </a>
  )
}

export default MessageScroller
