import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import useRealHeight from '../lib/use-real-height'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Header from '../components/header'
import MessageScroller from '../components/messageScroller'

const IndexPage = props => {
  const { data, errors } = props
  useRealHeight();

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }  

  return (
    <Layout>
      <SEO title="Eleven PR"/>
      <Container>

        <Header videoItems={site.HomeVideoSequence} />
        
        <section>
          <MessageScroller message="let’s create culture together"/>          
        </section>
      </Container>
    </Layout>
  )
}

export default IndexPage



export const query = graphql`
  query MelbourneIndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      HomeVideoSequence {
        title
        urlLink
        relatedProject {
          slug {
            current
          }
        }
      }
    }
  }
`